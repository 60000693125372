<template>
  <div class="payment-type">
    <div class="header">
      <div class="search-bar">
        <div class="search-item">
          <label style="width: 150px">付款方式名称</label>
          <el-input v-model="name" size="medium" placeholder="请输入内容"></el-input>
        </div>
        <div class="search-item">
          <label style="width: 150px">付款方式编码</label>
          <el-input v-model="code" size="medium" placeholder="请输入内容"></el-input>
        </div>
        <div class="search-item">
          <label>状态</label>
          <el-select v-model="businessType" placeholder="请选择" size="medium" clearable>
            <el-option v-for="item in businessTypeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-item" style="margin-left: 15px">
          <el-button type="primary" size="medium" @click="getPayTypeList">查询</el-button>
          <el-button size="medium" @click="handleResetClick">重置</el-button>
        </div>
      </div>
    </div>
    <div class="rigth-btn">
      <el-button type="primary" size="medium" @click="handleSyncClick">同步付款方式</el-button>
    </div>
    <div class="content">
      <div class="contentTable">
        <div class="tip-text">仅保存当页数据，修改后请及时保存!</div>
        <el-table ref="multipleTable" :data="tableData" style="width: 100%" row-key="id"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="80" align="center">
          </el-table-column>
          <el-table-column prop="code" label="code" align="center">
          </el-table-column>
          <el-table-column prop="name" label="付款方式编码名称" align="center">
          </el-table-column>
          <el-table-column prop="businessType" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.businessType === 0">未应用</span>
              <span v-if="scope.row.businessType === 1" style="color: #5D68FD">已应用</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <d-paging :pager="pager" @change="getPayTypeList" />
    </div>

    <div class="footer">
      <el-button type="primary" size="medium" @click="handleSaveClick">保存</el-button>
      <el-button size="medium" @click="handleCancelClick">取消</el-button>
    </div>
  </div>
</template>

<script>
import DPaging from "@/components/d-paging";

export default {
  components: {
    DPaging,
  },
  data () {
    return {
      multipleSelection: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      tableData: [],
      name: '',
      code: '',
      businessType: '',
      businessTypeOptions: [
        {
          label: '已应用',
          value: 1
        },
        {
          label: '未应用',
          value: 0
        },
      ],
    }
  },
  mounted () {
    this.getPayTypeList()
  },
  methods: {
    getPayTypeList () {
      let params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
      if (this.name) {
        params.name = this.name
      }
      if (this.code) {
        params.code = this.code
      }
      if (this.businessType || this.businessType === 0) {
        params.businessType = this.businessType
      }
      this.$api.paymentType.getPayTypeList(params).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          let checkedList = res.data.list.filter(item => item.businessType === 1)
          this.toggleSelection(checkedList)
          this.pager.count = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleResetClick () {
      this.pager = {
        count: 0,
        page: 1,
        rows: 10,
      }
      this.name = ''
      this.code = ''
      this.businessType = ''
      this.getPayTypeList()
    },
    handleSyncClick () {
      this.$api.paymentType.syncPayType().then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.pager.page = 1
          this.getPayTypeList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toggleSelection (rows) {
      if (Array.isArray(rows)) {
        this.$nextTick(() => {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        })
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    handleSaveClick () {
      let checkedIds = this.multipleSelection.map(item => item.id)
      let initialCheckedIds = this.tableData.filter(item => item.businessType === 1).map(item => item.id)
      let cancelledIds = initialCheckedIds.filter(item => !checkedIds.includes(item))
      let checkedAry = checkedIds.map(item => {
        return {
          id: item,
          isCheck: true
        }
      })
      let cancelledAry = cancelledIds.map(item => {
        return {
          id: item,
          isCheck: false
        }
      })
      let params = [...checkedAry, ...cancelledAry]
      this.$api.paymentType.updateReportSale(params).then(res => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.getPayTypeList()
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    handleCancelClick() {
      this.getPayTypeList()
    }
  },

}
</script>
<style lang="scss" scoped>
.payment-type {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  position: relative;
  box-sizing: border-box;

  .header {
    width: 100%;

    .search-bar {
      display: flex;
      align-items: center;

      .search-item {
        display: flex;
        align-items: center;
        line-height: 30px;

        label {
          margin-right: 15px;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .contentTable {
      height: 100%;
      overflow-y: auto;
    }

    .el-table {
      flex: 1;
      overflow-y: auto;
    }
  }

  .rigth-btn {
    width: 100%;
    text-align: right;
    margin: 20px 0;
  }

  .footer {
    position: absolute;
    text-align: right;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 10px;
  }
  .tip-text {
    margin-bottom: 8px;
    color: #F56C6C;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
